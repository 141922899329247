import { CheckboxCard } from '@velocity/ui'
import { useCallback, useMemo } from 'react'

import { useContent } from '@ngb-frontend/shared/context'
import {
  type ServiceOptionValue,
  type ServiceStepVariants,
  type ServicesFieldProps,
  type Validator,
} from '@ngb-frontend/shared/types'
import { validation as v } from '@ngb-frontend/shared/utils'

import { useServicesFieldStyles } from './ServicesField.styled'
import { FormField } from '../FormField/FormField'

import type { ChangeEvent } from 'react'
import type { FieldInputProps } from 'react-final-form'

export const ServicesField = <TService extends string = ServiceOptionValue>({
  variant,
  services,
  label,
  hideLabel,
  row,
  classes,
  onChange,
  noMargin,
  name = 'services',
  enabledServices,
  showHint,
}: ServicesFieldProps<TService>) => {
  const c = useContent()
  const internalClasses = useServicesFieldStyles({ row })

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, input: FieldInputProps<TService[]>) => {
      const value = e.target.value as TService
      const newValue = input.value.includes(value)
        ? input.value.filter((v) => v !== value)
        : [...input.value, value]

      onChange?.(newValue, value)
      input.onChange(newValue)
    },
    [onChange],
  )

  const validators: Partial<
    Record<ServiceStepVariants, Validator<TService[]>>
  > = useMemo(
    () => ({
      services: v.apply(v.minLength(1), {
        message: c('errors.minEntry'),
        type: 'errorType',
      }),
    }),
    [c],
  )

  const options = useMemo(
    () =>
      services.map((service) => ({
        title: c(`services.${service}.title`),
        details: c(`services.${service}.details`),
        value: service,
        disabled: !enabledServices?.[service],
      })),
    [c, services, enabledServices],
  )

  return (
    <FormField<TService[]>
      id="services-checkbox-group"
      label={label || c('fields.services.label')}
      hideLabel={hideLabel}
      name={name}
      validator={validators[variant]}
      required={false}
      hideOptionalLabel={true}
      classes={classes}
      noMargin={noMargin}
      group={true}
      hint={showHint ? c('sections.extraService.warning') : ''}
    >
      {({ input }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { onFocus, onBlur, value, ...restInput } = input

        return (
          <div
            data-e2e-component="services-checkbox-group"
            className={internalClasses.checkboxCardGroup}
          >
            {options.map((option) => (
              <div
                data-e2e-component={`${option.value}-checkbox`}
                key={option.value}
              >
                <CheckboxCard
                  {...restInput}
                  {...option}
                  id={`${option.value}-checkbox`}
                  checked={input.value.includes(option.value as TService)}
                  onChange={(e) => handleChange(e, input)}
                />
              </div>
            ))}
          </div>
        )
      }}
    </FormField>
  )
}

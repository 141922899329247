import React from 'react'

import { SystemCode } from '@ngb-frontend/shared/types'

import { Auth0AuthTemplate } from './Auth0AuthTemplate'
import { AzureAuthTemplate } from './AzureAuthTemplate'

import type { AzureAuthTemplateProps } from './AzureAuthTemplate'
import type { ReactNode } from 'react'

// Apps which implement Auth0 SSO. The rest use MS Azure.
export const AUTH0_SYS = [SystemCode.MYLP, SystemCode.SPLP]
export const AZURE_SYS = [SystemCode.MYAY, SystemCode.SFALD, SystemCode.SFLP]

export type AuthTemplateProps = Partial<AzureAuthTemplateProps> & {
  systemCode?: SystemCode
  children: ReactNode
}

const AuthComponentBase: React.FC<AuthTemplateProps> = ({
  children,
  systemCode,
  azureRequest,
  loadingComponent: LoadingComponent,
}) => {
  if (!systemCode) return children

  const usesAuth0 = AUTH0_SYS.includes(systemCode)

  if (usesAuth0) {
    return (
      <Auth0AuthTemplate
        loadingComponent={LoadingComponent}
        children={children}
      />
    )
  }

  return azureRequest && AZURE_SYS.includes(systemCode) ? (
    <AzureAuthTemplate
      azureRequest={azureRequest}
      loadingComponent={LoadingComponent}
    >
      {children}
    </AzureAuthTemplate>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  )
}

/**
 * Wrapper to conditionally render the auth implementation component. Requires
 * AuthProvider
 */
export const AuthComponent: React.FC<
  AuthTemplateProps & { disabled?: boolean }
> = ({ disabled, children, ...authBaseProps }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (disabled) return <>{children}</>

  return <AuthComponentBase children={children} {...authBaseProps} />
}

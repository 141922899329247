import { makeStyles } from '@material-ui/core'
import { List, Text } from '@velocity/ui'

import { FlexColumn } from '../FlexBox/FlexColumn'

interface InspectionDetailsProps {
  code: string
  when: string
  vehicleClass: string[]
}

export const useInspectionDetailsStyles = makeStyles(() => ({
  root: {
    color: 'var(--vel-color-neutral-900)',
  },
  inspectionTime: {
    marginTop: 'var(--vel-fixed-spacing-01)',
    marginBottom: 'var(--vel-fixed-spacing-01)',
  },
  classItem: {
    borderBottom: 'var(--vel-fixed-spacing-01) solid transparent',
    '& > div:nth-child(2)': {
      minWidth: 16,
    },
  },
}))

export const InspectionDetails: React.FC<InspectionDetailsProps> = ({
  code,
  when,
  vehicleClass,
}) => {
  const classes = useInspectionDetailsStyles()

  return (
    <FlexColumn className={classes.root}>
      <span className={classes.inspectionTime}>{when}</span>
      <Text variant="50">
        <List>
          {vehicleClass.map((classOption, key) => (
            <List.Item
              data-e2e-component={`${code}-${key}`}
              className={classes.classItem}
              key={key}
            >
              {classOption}
            </List.Item>
          ))}
        </List>
      </Text>
    </FlexColumn>
  )
}

import type { TCustomLoggerFn } from '@velocity/ui/logger/logger'

export const CustomVUILogger: TCustomLoggerFn = ({
  logLevel,
  message,
  meta,
}) => {
  if (
    logLevel !== 'error' ||
    !message.endsWith('Cannot get country name for [GLOBAL].')
  ) {
    // eslint-disable-next-line no-console
    console[logLevel](message, meta)
  }
}

import { Button } from '@velocity/ui'
import { useMemo } from 'react'

import { useContent } from '@ngb-frontend/shared/context'
import { formatPhoneNumber, useQuery } from '@ngb-frontend/shared/utils'

const getPhoneNumber = (country = '', defaultPhoneNumber: string) => {
  switch (country) {
    case '64201':
      return '+40-021-301-49-59'
    case '75601':
      return '+41-746-63-33'
    case '75201':
    case '75202':
      return '+46-8-501-122-00'
    default:
      return defaultPhoneNumber
  }
}

export const PhoneButton: React.FC = () => {
  const c = useContent()
  const query = useQuery()
  const phoneNumber = getPhoneNumber(
    query?.country,
    c('steps.shared.phoneNumber'),
  )

  const phone = useMemo(() => formatPhoneNumber(phoneNumber), [phoneNumber])

  return (
    <Button
      size="s"
      component="a"
      href={`tel:${phone}`}
      variant="primary-filled"
    >
      {phone}
    </Button>
  )
}

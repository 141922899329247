import { Text } from '@velocity/ui'
import React from 'react'

import { useDateTimeFieldStyles } from './DateTimeField.styled'
import { DateField } from '../DateField/DateField'
import { FlexBox } from '../FlexBox/FlexBox'
import { TimeField } from '../TimeField/TimeField'

import type { FormFieldWrapper } from '@ngb-frontend/shared/types'

export type DateTimeProps = {
  name?: string
  label?: string
  required?: boolean
  onChange?: (value: string) => void
  renderKey?: string
  id?: string
  min?: string
  max?: string
  ['aria-label']?: string
  ['data-e2e-component']?: string
}

export interface DateTimeFieldProps extends Pick<FormFieldWrapper, 'label'> {
  showTimeField?: boolean
  hint?: string
  inline?: boolean
  warning?: string
  dateProps?: DateTimeProps & { disabledDates?: string[] }
  timeProps?: DateTimeProps
}

export const DateTimeField: React.FC<DateTimeFieldProps> = ({
  hint,
  showTimeField,
  inline,
  label,
  dateProps,
  timeProps,
  warning,
}) => {
  const classes = useDateTimeFieldStyles({ inline })

  return (
    <>
      <FlexBox flexDirection={inline ? 'row' : 'column'}>
        <Text variant="100" bold>
          {label}
        </Text>
        <Text
          component="div"
          margin={{ bottom: !hint && !warning ? '07' : undefined }}
          className={classes.date}
        >
          <DateField
            hideLabel={!dateProps?.label}
            minDate={dateProps?.min}
            maxDate={dateProps?.max}
            {...dateProps}
            renderKey={
              dateProps?.renderKey || (dateProps?.required ? 'd1' : 'd0')
            }
            warning={!!warning}
            noMargin
          />
        </Text>
        {showTimeField && (
          <Text
            component="div"
            margin={{ bottom: !hint && !warning ? '07' : undefined }}
            className={classes.time}
          >
            <TimeField
              {...timeProps}
              minTime={timeProps?.min}
              maxTime={timeProps?.max}
              hideLabel={!timeProps?.label}
              renderKey={
                timeProps?.renderKey || (timeProps?.required ? 't1' : 't0')
              }
              warning={!!warning}
              noMargin
            />
          </Text>
        )}
      </FlexBox>
      {hint && (
        <Text
          variant="100"
          margin={{ top: '02', bottom: !warning ? '07' : undefined }}
        >
          {hint}
        </Text>
      )}
      {warning && <Text margin={{ top: '02', bottom: '07' }}>{warning}</Text>}
    </>
  )
}

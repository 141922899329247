import { Text } from '@velocity/ui'
import React, { useMemo } from 'react'

import { string } from '@ngb-frontend/shared/utils'

import { FlexBox } from '../../FlexBox/FlexBox'
import { RadioCard } from '../RadioCard'

import type {
  ContactFormValues,
  ContactType,
  RadioCardProps,
} from '@ngb-frontend/shared/types'

export interface DriverContactRadioCardProps
  extends Pick<
      RadioCardProps<ContactType>,
      'onChange' | 'checked' | 'id' | 'disabled'
    >,
    ContactFormValues {}

export const DriverContactRadioCard: React.FC<DriverContactRadioCardProps> = ({
  firstName,
  lastName,
  email,
  phone,
  ...radioCardProps
}) => {
  const content = useMemo(() => {
    return {
      contactInfo: (
        <Text variant="200" data-dd-action-name="User email">
          {email}
          <FlexBox mt={1} data-dd-action-name="User phone">
            {phone}
          </FlexBox>
        </Text>
      ),
      title: (
        <Text
          variant="300"
          bold
          margin={{ bottom: '02' }}
          data-dd-action-name="User name"
        >
          {string([firstName, lastName])}
        </Text>
      ),
    }
  }, [email, firstName, lastName, phone])

  return (
    <RadioCard<ContactType>
      {...radioCardProps}
      borderColor="neutral"
      hideCollapseIndicator={true}
      name="driver-radio-card"
      title={content.title}
      content={content.contactInfo}
    />
  )
}

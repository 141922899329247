import { makeStyles } from '@material-ui/core'
import { Headline, Text } from '@velocity/ui'
import React, { useMemo } from 'react'

import { useContent } from '@ngb-frontend/shared/context'

import { NameField, EmailField, PhoneField } from '..'
import { FlexColumn } from '../FlexBox/FlexColumn'

import type { Theme } from '@material-ui/core'
import type { ContactFormValues } from '@ngb-frontend/shared/types'

export interface OtherContactSectionProps {
  isHidden?: boolean
  fieldNames?: ContactFormValues
  compact?: boolean
}

export const useContactSectionStyles = makeStyles<
  Theme,
  { isHidden?: OtherContactSectionProps['isHidden'] }
>(() => ({
  container: ({ isHidden }) => ({
    visibility: isHidden ? 'hidden' : 'visible',
  }),
}))

const otherContactDefaultFieldNames: OtherContactSectionProps['fieldNames'] = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phone: 'phone',
}

export const OtherContactSection: React.FC<OtherContactSectionProps> = ({
  isHidden,
  fieldNames = otherContactDefaultFieldNames,
  compact,
}) => {
  const c = useContent()
  const classes = useContactSectionStyles({ isHidden })

  const labels = useMemo(() => {
    return {
      other: c('fields.contact.otherContactTitle'),
      firstName: c('fields.shared.firstName'),
      lastName: c('fields.shared.lastName'),
      email: c('fields.shared.email'),
      emailHint: c('fields.shared.emailHint'),
      phone: c('fields.shared.phone'),
    }
  }, [c])

  return (
    <FlexColumn className={classes.container}>
      {compact ? (
        <Headline margin={{ y: '05' }} variant="200">
          {labels.other}
        </Headline>
      ) : (
        <Text variant="100" bold margin={{ bottom: '05' }}>
          {labels.other}
        </Text>
      )}
      <NameField
        name={fieldNames.firstName}
        label={labels.firstName}
        maxLength={35}
        noValidation={isHidden}
        placeholder=""
      />
      <NameField
        name={fieldNames.lastName}
        label={labels.lastName}
        maxLength={35}
        noValidation={isHidden}
        placeholder=""
      />
      <EmailField
        name={fieldNames.email}
        label={labels.email}
        hint={labels.emailHint}
        noValidation={isHidden}
        placeholder=""
      />
      <PhoneField
        name={fieldNames.phone}
        label={labels.phone}
        noValidation={isHidden}
      />
    </FlexColumn>
  )
}

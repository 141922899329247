import { SearchIcon } from '@velocity/icons/system'
import { Grid, PlacesAutocomplete, Text } from '@velocity/ui'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useContent, useLocalization } from '@ngb-frontend/shared/context'
import { useReverseOSM } from '@ngb-frontend/shared/utils'

import { useAddressFieldsStyles } from './AddressFields.styled'
import { CityField } from '../CityField/CityField'
import { CountyField } from '../CountyField/CountyField'
import { HouseNumberField } from '../HouseNumberField/HouseNumberField'
import { PostcodeField } from '../PostcodeField/PostcodeField'
import { StreetNameField } from '../StreetNameField/StreetNameField'

import type {
  Place,
  PlacesAutocompleteInputChangeEvent as PlacesInputEvt,
  PlacesAutocompleteSuggestionsChangeEvent as PlacesSuggestionsEvt,
  VelFocusEvent,
} from '@velocity/ui'

interface AddressFieldsProps {
  label?: string
  showFieldsOnInit?: boolean
  required?: boolean
  disabled?: boolean
  showCounty?: boolean
  onSelect?: (place?: Place & { county?: string }) => void
  fieldNames?: Partial<typeof defaultFieldNames>
  error?: boolean
  hideFieldsStyle?: 'visibility' | 'display'
}

const defaultFieldNames = {
  houseNumber: 'address.house_number',
  city: 'address.city',
  county: 'address.county',
  streetName: 'address.street',
  postcode: 'address.postcode',
}

export const AddressFields: React.FC<AddressFieldsProps> = ({
  label,
  onSelect,
  showFieldsOnInit,
  required,
  disabled,
  showCounty,
  error: formError,
  fieldNames = {},
  hideFieldsStyle = 'visibility',
}) => {
  const [showPlacesError, setShowPlacesError] = useState(false)
  const [addressVisible, setAddressVisible] = useState(showFieldsOnInit)
  const [selectedPlace, setSelectedPlace] = useState<Place>()
  const hasPlacesInput = useRef(false)
  const hasSuggestions = useRef(false)
  const { data: placeOSMData, error: osmError } = useReverseOSM(selectedPlace)
  const { locale } = useLocalization()
  const c = useContent()
  const country = locale.split('-')[1]

  const mergedFieldNames = { ...defaultFieldNames, ...fieldNames }
  const classes = useAddressFieldsStyles({ addressVisible, hideFieldsStyle })

  // this does not fire when all the input is selected and erased that's why
  // we ensure errors of empty inputs are cleared in the handleInputChange
  const handleSuggestionsOrFocusChange = useCallback(
    (e: PlacesSuggestionsEvt | VelFocusEvent) => {
      const { type, target } = e
      if (type === 'blur') return setShowPlacesError(false)
      if (type === 'change') {
        hasSuggestions.current = !!target.suggestions.length
      }
      // this updates both onSuggestionChange and onFocusChange events
      setShowPlacesError(hasPlacesInput.current && !hasSuggestions.current)
    },
    [],
  )

  const handleInputChange = useCallback((e: PlacesInputEvt) => {
    hasPlacesInput.current = !!e.target.value
    !hasPlacesInput.current && setShowPlacesError(false)
  }, [])

  useEffect(() => {
    if (formError || placeOSMData) setAddressVisible(true)
  }, [formError, placeOSMData])

  useEffect(() => {
    // since we do the lookup for the county, some of the fields can also serve
    // as google places autocomplete fallbacks
    if (selectedPlace && (placeOSMData || osmError)) {
      const geocodingComponents = placeOSMData?.features[0].properties.geocoding
      onSelect?.({
        ...selectedPlace,
        city: selectedPlace.city || geocodingComponents?.city || '',
        streetNumber:
          selectedPlace.streetNumber || geocodingComponents?.housenumber || '',
        streetName:
          selectedPlace.streetName || geocodingComponents?.street || '',
        postalCode:
          selectedPlace.postalCode || geocodingComponents?.postcode || '',
        ...(showCounty ? { county: geocodingComponents?.county } : {}),
      })
    }
  }, [onSelect, osmError, placeOSMData, selectedPlace, showCounty])

  return (
    <Grid>
      <Grid.Item LG={4}>
        <Text variant="100" bold margin={{ bottom: '01' }}>
          {label ? label : c('fields.placesAutoComplete.label')}
        </Text>
        <PlacesAutocomplete
          disabled={disabled}
          placeholder={c('fields.placesAutoComplete.placeholder')}
          onSelect={(e) => setSelectedPlace(e.target.place)}
          country={country}
          endAdornment={formError || showPlacesError ? undefined : SearchIcon}
          error={formError || showPlacesError}
          onSuggestionsChange={handleSuggestionsOrFocusChange}
          onInputChange={handleInputChange}
          onBlur={handleSuggestionsOrFocusChange}
          onFocus={handleSuggestionsOrFocusChange}
        />
        {showPlacesError && (
          <Text variant="100" margin={{ top: '01' }} className={classes.error}>
            {c('fields.placesAutoComplete.noResult')}
          </Text>
        )}
        <Text
          variant="100"
          className={classes.trigger}
          margin={{ bottom: '05', top: '02' }}
          data-e2e-component="address-toggle"
          onClick={() => setAddressVisible(!addressVisible)}
        >
          {c('fields.placesAutoComplete.enterManually')}
        </Text>
        <div className={classes.container} data-e2e-component="address-fields">
          <HouseNumberField
            name={mergedFieldNames.houseNumber}
            required={required}
            disabled={disabled}
          />
          <StreetNameField
            name={mergedFieldNames.streetName}
            required={required}
            disabled={disabled}
          />
          <CityField name={mergedFieldNames.city} disabled={disabled} />
          {showCounty && (
            <CountyField
              name={mergedFieldNames.county}
              required={required}
              disabled={disabled}
            />
          )}
          <PostcodeField
            name={mergedFieldNames.postcode}
            required={required}
            disabled={disabled}
          />
        </div>
      </Grid.Item>
    </Grid>
  )
}

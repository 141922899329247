import { Box } from '@material-ui/core'
import React from 'react'

import type { BoxProps } from '@material-ui/core'

export const FlexBox: React.FC<BoxProps> = ({ children, ...other }) => (
  <Box display="flex" width="100%" {...other}>
    {children}
  </Box>
)

import React from 'react'

import { FlexBox } from './FlexBox'

import type { BoxProps } from '@material-ui/core'

export const FlexColumn: React.FC<BoxProps> = ({ children, ...other }) => (
  <FlexBox flexDirection="column" justifyContent="flex-end" {...other}>
    {children}
  </FlexBox>
)
